import React, { useState, useEffect } from 'react';
import api from '../services/api';
import Select from 'react-select';
import './SkuMapping.scss';

const SkuMappingAdvanced = () => {
  const [shopifyProducts, setShopifyProducts] = useState([]);
  const [nssProducts, setNssProducts] = useState([]);
  const [selectedShopifySKUs, setSelectedShopifySKUs] = useState([]);
  const [selectedNssSKUs, setSelectedNssSKUs] = useState([]);
  const [skuMappings, setSkuMappings] = useState([]);

  // Fetch Shopify and NSS products
  const fetchInitialData = async () => {
    try {
      const shopifyResponse = await api.getShopifyProducts();
      const nssResponse = await api.getNssProducts();
      const mappingResponse = await api.getSkuMappings();

      setShopifyProducts(
        shopifyResponse.data.map((product) => ({
          value: product.sku,
          label: product.name,
        })),
      );

      setNssProducts(
        nssResponse.data.map((product) => ({
          value: product.nss_sku,
          label: product.product_name,
        })),
      );

      setSkuMappings(mappingResponse.data);
    } catch (error) {
      console.log(error);
      alert('Failed to fetch initial data');
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  // Update NSS SKU with quantity
  const handleNssSkuChange = (selectedOptions) => {
    const updatedSelectedNssSKUs = selectedOptions.map((sku) => ({
      ...sku,
      quantity: 1, // Default quantity is 1
    }));
    setSelectedNssSKUs(updatedSelectedNssSKUs);
  };

  // Handle quantity change
  const handleQuantityChange = (index, quantity) => {
    const updatedNssSKUs = [...selectedNssSKUs];
    updatedNssSKUs[index].quantity = quantity;
    setSelectedNssSKUs(updatedNssSKUs);
  };

  // Submit mapping
  const handleSubmit = async (event) => {
    event.preventDefault();
    const skuMapping = {
      shopify_skus: selectedShopifySKUs.map((sku) => sku.value),
      nss_skus: selectedNssSKUs.map((sku) => ({
        sku: sku.value,
        quantity: sku.quantity,
      })),
    };
    try {
      await api.updateSkuMapping(skuMapping);
      // Clear the form and reload data
      setSelectedShopifySKUs([]);
      setSelectedNssSKUs([]);
      fetchInitialData(); // Reloads the data
    } catch (error) {
      console.log('Could not save mappings', error);
    }
  };

  // Delete mapping
  const handleDelete = async (mappingId) => {
    try {
      await api.deleteSkuMapping(mappingId);
      fetchInitialData(); // Reload mappings after deletion
    } catch (error) {
      console.log('Failed to delete mapping', error);
    }
  };

  return (
    <div className="sku-mapping-advanced">
      <h2>Advanced SKU Mapping</h2>
      <form onSubmit={handleSubmit} className="sku-mapping-form">
        <div className="sku-selection">
          <div className="shopify-select">
            <label>Select Shopify SKUs:</label>
            <Select
              isMulti
              options={shopifyProducts}
              value={selectedShopifySKUs}
              onChange={setSelectedShopifySKUs}
              className="select-box"
            />
          </div>
          <div className="nss-select">
            <label>Select NSS SKUs (with quantity):</label>
            <Select
              isMulti
              options={nssProducts}
              value={selectedNssSKUs}
              onChange={handleNssSkuChange}
              className="select-box"
            />
            {selectedNssSKUs.map((sku, index) => (
              <div key={sku.value} className="quantity-input">
                <label>{sku.label} Quantity:</label>
                <input
                  type="number"
                  value={sku.quantity}
                  min="1"
                  onChange={(e) =>
                    handleQuantityChange(index, parseInt(e.target.value))
                  }
                />
              </div>
            ))}
          </div>
        </div>
        <button type="submit" className="submit-button">
          Add/Update SKU Mapping
        </button>
      </form>

      <h3>Current Mappings</h3>
      <ul className="sku-list">
        {skuMappings.map((mapping, index) => (
          <li key={index} className="sku-item">
            <span>
              {mapping.shopify_skus.join(', ')} →{' '}
              {mapping.nss_skus
                .map((sku) => `${sku.sku} (x${sku.quantity})`)
                .join(', ')}
            </span>
            <button
              onClick={() => handleDelete(mapping._id)}
              className="delete-button">
              X
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SkuMappingAdvanced;
