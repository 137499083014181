import React, { useState, useEffect } from 'react';
import countriesList from '../helpers/countries';
import Select from 'react-select';
import api from '../services/api';
import './CountryProductRules.scss';

const CountryProductRules = () => {
  const [countries, setCountries] = useState([]);
  const [nssProducts, setNssProducts] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [baseProducts, setBaseProducts] = useState([]);
  const [additionalProducts, setAdditionalProducts] = useState([]);
  const [countryProductRules, setCountryProductRules] = useState([]);

  useEffect(() => {
    // Fetch NSS products from the backend to populate the selects
    const fetchNssProducts = async () => {
      const result = await api.getNssProducts();
      const productOptions = result.data.map((product) => ({
        value: product.nss_sku,
        label: product.product_name,
      }));
      setNssProducts(productOptions);
    };

    const fetchCountryProductRules = async () => {
      const result = await api.getCountryProductRules();
      setCountryProductRules(result.data);
    };

    fetchNssProducts();
    fetchCountryProductRules();

    setCountries(
      countriesList.map(({ code, name }) => ({
        value: code,
        label: name,
      })),
    );
  }, []);

  const resetSelections = () => {
    setSelectedCountry(null);
    setBaseProducts([]);
    setAdditionalProducts([]);
  };

  const handleSaveRule = async () => {
    const ruleData = {
      country_code: selectedCountry.value,
      base_product_skus: baseProducts.map((product) => product.value),
      additional_products: additionalProducts.map((product) => product.value),
    };

    try {
      await api.saveCountryProductRule(ruleData);
      alert('Rule saved successfully!');
      resetSelections();
    } catch (error) {
      alert('Failed to save rule');
    }
  };

  return (
    <div className="country-product-rules">
      <h2>Create Country Product Rule</h2>

      <label>Country:</label>
      <Select
        options={countries}
        onChange={setSelectedCountry}
        value={selectedCountry}
      />

      <label>Base Product:</label>
      <Select
        options={nssProducts}
        isMulti
        onChange={setBaseProducts}
        value={baseProducts}
      />

      <label>Additional Products:</label>
      <Select
        options={nssProducts}
        isMulti
        onChange={setAdditionalProducts}
        value={additionalProducts}
      />

      <button onClick={handleSaveRule} className="save-button">
        Save Rule
      </button>

      <h3>Current Country Product Rules</h3>
      <ul className="rules-list">
        {countryProductRules.map((rule) => (
          <li key={rule.country_code} className="rule-item">
            {rule.country_code},{' Base Products: '}
            {rule.base_product_skus.join(', ')},{' Additional Products: '}
            {rule.additional_products.join(', ')}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CountryProductRules;
