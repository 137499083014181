import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import SkuMapping from './components/SkuMapping';
import CountryRules from './components/CountryRules';
import NSSProducts from './components/NSSProducts';
import CountryProductRules from './components/CountryProductRules';
import './styles/app.scss';

function App() {
  return (
    <Router>
      <div className="app-layout">
        <Sidebar />
        <div className="content">
          <Routes>
            <Route path="/sku-mapping" element={<SkuMapping />} />
            <Route path="/country-rules" element={<CountryRules />} />
            <Route path="/nss-products" element={<NSSProducts />} />
            <Route
              path="/country-product-rules"
              element={<CountryProductRules />}
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
