import React, { useState, useEffect } from 'react';
import api from '../services/api';
import './NSSProducts.scss';

const NSSProducts = () => {
  const [nssProducts, setNssProducts] = useState([]);
  const [syncingNSS, setSyncingNSS] = useState(false); // Track NSS sync state
  const [syncingShopify, setSyncingShopify] = useState(false); // Track Shopify sync state

  useEffect(() => {
    async function fetchNssProducts() {
      const result = await api.getNssProducts();
      setNssProducts(result.data);
    }
    fetchNssProducts();
  }, []);

  const handleSyncNSS = async () => {
    setSyncingNSS(true);
    try {
      const syncResult = await api.syncProductsWithNSS();
      if (syncResult.status === 200) {
        alert('NSS products synced successfully!');
        setNssProducts(syncResult.data);
      }
    } catch (error) {
      alert('Failed to sync products with NSS.');
    } finally {
      setSyncingNSS(false);
    }
  };

  const handleSyncShopify = async () => {
    setSyncingShopify(true);
    try {
      const syncResult = await api.syncProductsWithShopify();
      if (syncResult.status === 200) {
        alert('Shopify products synced successfully!');
      }
    } catch (error) {
      alert('Failed to sync products with Shopify.');
    } finally {
      setSyncingShopify(false);
    }
  };

  return (
    <div className="nss-products">
      <h2>NSS Products</h2>
      <div className="sync-buttons">
        <button
          onClick={handleSyncNSS}
          disabled={syncingNSS}
          className="sync-button">
          {syncingNSS ? 'Syncing with NSS...' : 'Sync products with NSS'}
        </button>
        <button
          onClick={handleSyncShopify}
          disabled={syncingShopify}
          className="sync-button">
          {syncingShopify
            ? 'Syncing with Shopify...'
            : 'Sync products with Shopify'}
        </button>
      </div>
      <form className="nss-form">
        <h3>Current NSS Products</h3>
        <ul className="products-list">
          {nssProducts.map((product) => (
            <li key={product.nss_sku} className="product-item">
              {product.product_name} ({product.nss_sku}) - {product.weight}g, $
              {product.declared_value}
            </li>
          ))}
        </ul>
      </form>
    </div>
  );
};

export default NSSProducts;
