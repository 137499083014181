import React, { useState, useEffect } from 'react';
import countriesList from '../helpers/countries';
import Select from 'react-select';
import api from '../services/api';
import './CountryRules.scss';
import Loader from './common/Loader';

const logisticsAttributesOptions = [
  { value: 'General Cargo', label: 'General Cargo' },
  { value: 'Battery Included/Magnetic', label: 'Battery Included/Magnetic' },
  { value: 'Battery Only', label: 'Battery Only' },
  { value: 'Special', label: 'Special' },
];

const CountryRules = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [countryRules, setCountryRules] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [weight, setWeight] = useState('');
  const [declaredValue, setDeclaredValue] = useState('');
  const [logisticsAttribute, setLogisticsAttribute] = useState(null);

  const fetchCountryRules = async () => {
    setIsLoading(true);
    try {
      const result = await api.getCountryRules();
      setCountryRules(result.data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCountryRules();

    setCountries(
      countriesList.map(({ code, name }) => ({
        value: code,
        label: name,
      })),
    );
  }, []);

  const resetForm = () => {
    setSelectedCountry(null);
    setWeight('');
    setDeclaredValue('');
    setLogisticsAttribute('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const countryRule = {
      country_code: selectedCountry.value,
      country_name: selectedCountry.label,
      weight_limit: weight,
      declared_value_limit: declaredValue,
      logistics_attribute: logisticsAttribute?.value || '',
    };

    try {
      await api.updateCountryRules(countryRule);
      resetForm();
      fetchCountryRules();
    } catch (error) {
      console.log('Could not save country rule', error);
    }
  };

  // Delete rule
  const handleDelete = async (ruleId) => {
    try {
      await api.deleteCountryRule(ruleId);
      fetchCountryRules(); // Reload country rules after deletion
    } catch (error) {
      console.log('Failed to delete country rule', error);
    }
  };

  return (
    <div className="country-rules">
      <h2>Country Rules</h2>
      <form onSubmit={handleSubmit} className="country-form">
        <div className="field">
          <label>Country Code:</label>
          <Select
            options={countries}
            onChange={setSelectedCountry}
            value={selectedCountry}
          />
        </div>
        <div className="field">
          <label>Product Attribute:</label>
          <Select
            options={logisticsAttributesOptions}
            onChange={setLogisticsAttribute}
            value={logisticsAttribute}
          />
        </div>
        <div className="field">
          <label>Weight Limit:</label>
          <input
            type="number"
            name="weight_limit"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
        </div>
        <div className="field">
          <label>Declared Value Limit:</label>
          <input
            type="number"
            name="declared_value_limit"
            value={declaredValue}
            onChange={(e) => setDeclaredValue(e.target.value)}
          />
        </div>
        <button type="submit">Add/Update Country Rule</button>
      </form>

      <h3>Current Country Rules</h3>
      <div className="current-rules-wrapper">
        {isLoading ? (
          <Loader />
        ) : countryRules.length ? (
          <ul className="rules-list">
            {countryRules.map((rule) => (
              <li key={rule.country_code} className="rule-item">
                <span>
                  {rule.country_name}: {rule.weight_limit}g, $
                  {rule.declared_value_limit}
                  {rule.logistics_attribute
                    ? `, ${rule.logistics_attribute}`
                    : ''}
                </span>
                <button
                  onClick={() => handleDelete(rule._id)}
                  className="delete-button">
                  X
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <span>No country rules yet...</span>
        )}
      </div>
    </div>
  );
};

export default CountryRules;
